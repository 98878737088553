import { NavLink } from "react-router-dom"

export const Header = () => {
    return (
        <header className="header">
            <h1><NavLink to="/">Bohdan Lapa<span>Fullstack Developer</span></NavLink></h1>
            <nav>
                <ul className="header__menu">
                    <li><NavLink to="/">CV</NavLink></li>
                    <li><NavLink to="/timeline/"><span>Tech </span>Timeline</NavLink></li>
                    <li><NavLink to="/works/"><span>Selected </span>Works</NavLink></li>
                </ul>
            </nav>
        </header>
    )
}